import React, { useState } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './global.css'

import DigitalAccelerator from './assets/img/digital-accelerator'

import { useMsal, useAccount } from "@azure/msal-react"
import { InteractionType } from "@azure/msal-browser"

import Background from './assets/img/header.jpeg'

const Home = () => {
  const [email, setEmail] = useState("")

  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  const notifySuccess = () => toast("L'e-mail di accesso è stata inviata")
  const notifyError = () => toast("qualcosa è andato storto")

  const authRequest = { scopes: ["openid", "profile"] }

  const openInNewTab = (url) => window.location.replace(url)

  const handleChange = (e) => setEmail(e.target.value)

  const handleConfirm = () => getThinkificSSOMagic(email)

  const getThinkificSSOMagic = async (email) => {
    axios({
      url: process.env.REACT_APP_SSO_API,
      method: "post",
      data: {
        query: `
        query GetCDPThinkificMailLink($userEmail:String){
          GetCDPThinkificMailLink(userEmail: $userEmail)
        }`,
        variables:
        {
          "userEmail":email
        }
      }
    })
    .then((result) => {
      notifySuccess()
      console.log('ssoLink:', result.data.data.GetCDPThinkificMailLink)
    })
    .catch((err) => {
      notifyError()
    })
  }

  return (
  <>
    <body>
      <ToastContainer />
      <div class="XnpModal">
        <div class="XnpModal__content XnpModal__content--aBreveRiceveraiUnLink">
          <h3 class="XnpModal__title">
            A breve riceverai una mail con un link per accedere alla piattaforma. Ti basterà cliccare per visualizzare i tuoi progressi su Xcelerator.
          </h3>
          <p>
            Ricorda però che questa procedura sarà disponibile fino al 30 maggio e, per accedere al nuovo sistema bastano pochi minuti.
          </p>
          <a href="#" class="CDPButton" data-function="closeModal">
            Ho capito
          </a>
        </div>
        <div class="XnpModal__content XnpModal__content--indirizzoNonRegistrato">
          <h3 class="XnpModal__title">
            Il tuo indirizzo non risulta registrato su Digital Xcelerator.
          </h3>
          <p>
            Se vuoi accedere alla piattaforma di e-learning di CDP Venture Capital, crea la tua login unica.
          </p>
          <div class="CDPResponsive">
            <div>
              <a href="#" class="CDPButton" data-function="registratiModal">
                Registrati
              </a>
            </div>
            <div>
              <a href="#" class="CDPButton CDPButton--reverse" data-function="closeModal">
                Non ora
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="Xnp">
        <div class="Xnp__CDPDigitalXcelerator">
          <div class="Xnp__container">
            <img src={require("./assets/img/cdp-x-digital-xcelerator.png")} alt="CDP Digital Xcelerator" />
          </div>
        </div>
        <header class="Xnp__header">
          <div class="bg-cover" style={{backgroundImage: `url(${Background})` }}>
            <div class="Xnp__header__content px-48">
              <div class="Xnp__header__text">
                <h2 class="Xnp__header__title">
                  Per accedere ai tuoi corsi su Digital Xcelerator c'è una nuova procedura.
                </h2>
                <p>
                  <small>
                    Hai tempo fino al 30 maggio 2022 per completare la nuova registrazione, che ti permetterà di salvare tutti i tuoi progressi.
                  </small>
                </p>
              </div>
              <div class="Xnp__header__logo">
                {/* <img class="Xnp__header__logoimage" src={require("./assets/img/digital-xcelerator.svg")} alt="Digital Xcelerator"/> */}
                <DigitalAccelerator class="w-[150px]" />
              </div>
            </div>
          </div>
        </header>
        <main class="Xnp__main">
          <section class="Xnp__section">
            <div class="Xnp__container padding-block">
              <div>
                <h3 class="CDPTitle">
                  Perché questo cambiamento?
                </h3>
                <p>
                  Perché Digital Xcelerator è entrato a far parte dell'ecosistema di piattaforme digitali di CDP Venture Capital. Cosa significa? Non dovrai più memorizzare tante password: con un'unica login potrai entrare in tutti i nostri servizi.
                </p>
              </div>
            </div>
          </section>
          <section class="Xnp__steps Xnp__section Xnp__section--highlight">
            <div class="Xnp__container">
              <h3 class="CDPTitle">
                Se sei un nuovo utente oppure non hai ancora le nuove credenziali, ecco come fare:
              </h3>
            </div>
            <div class="Xnp__container">
              <ol class="CDP__numberedList">
                <li>
                  Clicca sul pulsante registrati qui sotto, inserisci i tuoi dati sul portale di registrazione, dove creeremo il tuo profilo.
                </li>
                <li>
                  Otterrai subito una login, con cui potrai entrare su Xcelerator e le altre piattaforme.
                </li>
              </ol>
              <div>
                <a href="#" class="CDPButton" data-function="registratiModal">
                  Registrati
                </a>
              </div>
            </div>
            <div class="Xnp__container Xnp__container--separator">
              <h3 class="CDPTitle">
                Hai già la tua login per le nostre piattaforme digitali?
              </h3>
            </div>
            <div class="Xnp__container">
              <ul class="CDP__list">
                <li>
                  Ti basterà cliccare sul pulsante login e potrai accedere a Digital Xcelerator con la nuova procedura.
                </li>
              </ul>
              <div>
                <a href="/login" class="CDPButton" data-function="login">
                  Login
                </a>
              </div>
            </div>
          </section>
          <section class="Xnp__section margin-top">
            <div class="Xnp__container">
              <div>
                <h3 class="CDPTitle">
                  Se sei già registrato su Xcelerator ma non puoi portare a termine la nuova procedura ora puoi sfruttare un login temporaneo.
                </h3>
                <p class="Xnp__paragraph">
                  Perche Digital Xcelerator è entrato a far parte dell'ecosistema di piattaforme digitali di CDP Venture Capital. Cosa significa? Non dovrai piu memorizzare tante password: con un'unica login potrai entrare in tutti i nostri servizi.
                </p>
              </div>
            </div>
            <div class="Xnp__container">
              <form action="#" class="Xnp__form">
                <label for="inserisciEmail">
                  Inserisci l'email per login temporaneo
                </label>
                <div class="CDPResponsive">
                  <input type="email" placeholder="Inserisci qui la tua email." id="inserisciEmail" value={email} onChange={handleChange} />
                  <input type="submit" class="CDPButton" value="Invia" onClick={handleConfirm}/>
                </div>
              </form>
            </div>
          </section>
          <section class="Xnp__section super-margin-block">
            <div class="Xnp__container">
              <h3 class="CDPTitle CDPTitle--strong">
                Hai bisogno di aiuto?
              </h3>
            </div>
            <div class="Xnp__container">
              <div>
                <p>
                  Se hai difficoltà nel creare le tue credenziali scrivici all'indirizzo
                  <a href="mailto:support@cdpventurecapital.it">
                    support@cdpventurecapital.it
                  </a>
                </p>
              </div>
              <div>
                <a href="#" class="CDPButton CDPButton--reverse">
                  Contattaci
                </a>
              </div>
            </div>
          </section>
          <section class="ecosystem">
            <h2>
              L’ecosistema dell’innovazione italiano
            </h2>
            <ul>
              <li>
                <a href="https://www.mise.gov.it/index.php/it/per-l-impresa/innovazione2" target="_blank" rel="noopener noreferrer">
                  <img srcset="https://xtra.cdpventurecapital.it/img/CDP__logo-mise@360w.png 2x,https://xtra.cdpventurecapital.it/img/CDP__logo-mise@180w.png 1x" alt="Ministero dello sviluppo economico" width="170"/>
                </a>
              </li>
              <li>
                <a href="https://www.esteri.it/mae/it/servizi/imprese" target="_blank" rel="noopener noreferrer">
                  <img srcset="https://xtra.cdpventurecapital.it/img/CDP__logo-ministero-esteri@360w.png 2x,https://xtra.cdpventurecapital.it/img/CDP__logo-ministero-esteri@180w.png 1x" alt="Ministero degli affari esteri e della cooperazione internazionale" width="170"/>
                </a>
              </li>
              <li>
                <a href="http://www.ministroperilsud.gov.it/it/approfondimenti/piano-sud-2030/" target="_blank" rel="noopener noreferrer">
                    <img srcset="https://xtra.cdpventurecapital.it/img/CDP__logo-misud@360w.png 2x,https://xtra.cdpventurecapital.it/img/CDP__logo-misud@180w.png 1x" alt="Ministro per il Sud e la Coesione territoriale" width="170"/>
                </a>
              </li>
              <li>
                <a href="https://innovazione.gov.it/it/innovazione-per-le-imprese/" target="_blank" rel="noopener noreferrer">
                    <img srcset="https://xtra.cdpventurecapital.it/img/CDP__logo-MITD.png 2x,https://xtra.cdpventurecapital.it/img/CDP__logo-MITD.png 1x" alt="Ministro per l'innovazione tecnologica e digitale" width="170"/>
                </a>
              </li>
              <li>
                <a href="https://www.invitalia.it/cosa-facciamo/creiamo-nuove-aziende/sistema-invitalia-startup" target="_blank" rel="noopener noreferrer">
                    <img srcset="https://xtra.cdpventurecapital.it/img/CDP__logo-invitalia@360w.png 2x,https://xtra.cdpventurecapital.it/img/CDP__logo-invitalia@180w.png 1x" alt="Invitalia" width="170"/>
                </a>
              </li>
              <li>
                <a href="https://www.ice.it/it/settori/startup-innovazione" target="_blank" rel="noopener noreferrer">
                    <img srcset="https://xtra.cdpventurecapital.it/img/CDP__logo-ita@360w.png 2x, https://xtra.cdpventurecapital.it/img/CDP__logo-ita@180w.png 1x" alt="Italian Trade Agency" width="170"/>
                </a>
              </li>
            </ul>
          </section>
          <footer class="bg-[#4d4d4d]">
            <ul>
              <li class="info">
                <p>
                  Copyright 2022 © CDP Venture Capital SGR ​S.p.A.
                </p>
                <p>
                  Capitale Sociale 2.595.629,40 euro Iscrizione al Registro delle Imprese di Roma
                </p>
                <p>
                  Codice Fiscale e P. IVA n. 08480100018
                </p>
                <p>
                  Società iscritta all’Albo dei gestori di FIA al n. 59
                ​</p>
              </li>
              <li class="menu">
                <h2>
                  Link Rapidi
                </h2>
                <ul>
                  <li>
                    <a href="https://www.cdpventurecapital.it/cdp-venture-capital/it/lavora_con_noi.page" target="_blank">Lavora con noi</a>
                  </li>
                  <li>
                    <a href="https://www.cdpventurecapital.it/cdp-venture-capital/it/informativa_pricvacy_xtra.page" target="_blank">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="https://www.cdpventurecapital.it/cdp-venture-capital/it/informativa_cookie_xtra_1.page" target="_blank">Cookie Policy</a>
                  </li>
                  <li>
                    <a href="https://www.cdpventurecapital.it/cdp-venture-capital/it/disclaimer.page" target="_blank">Disclaimer</a>
                  </li>
                  <li>
                    <a href="https://www.cdpventurecapital.it/cdp-venture-capital/it/reclami.page" target="_blank">Reclami</a>
                  </li>
                </ul>
              </li>
              <li class="social">
                <h2>
                  Contatti
                </h2>
                <address>
                  <p>
                    CDP Venture Capital Sgr<br/>
                    Via Arduino, 22<br/>
                    00162 Roma<br/>
                  </p>
                </address>
              </li>
            </ul>
          </footer>
        </main>
      </div>
    </body>
  </>
  )
}

export default Home

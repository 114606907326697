import React, { useEffect } from 'react'
import './global.css'
import axios from 'axios'

import { MsalAuthenticationTemplate, useMsal, useAccount } from "@azure/msal-react"
import { InteractionType } from "@azure/msal-browser"

const Home = () => {
  const { accounts } = useMsal()
  const account = useAccount(accounts[0] || {})

  useEffect(() => {
    console.log('account:', account)
  }, [account])

  const authRequest = {
      scopes: ["openid", "profile"]
  }

  const openInNewTab = (url) => window.location.replace(url)

  const getMentornitySSO = async(firstName, lastName, email, type) => {

    const getMentornityToken = `
    query GetMentornityToken($userEmail: String, $firstName:String, $lastName:String, $mentorRole:String, $program:String){
      GetMentornityToken(userEmail:$userEmail, firstName:$firstName, lastName:$lastName, mentorRole:$mentorRole, program:$program)
    }`

    let userType = 'mentee'
    if(type === 'Esperto') userType = 'mentor'  // User is either Esperto or Startup

    let queryVar = {
      firstName: firstName,
      lastName: lastName,
      userEmail: email,
      mentorRole: userType,
      program: 'cdp-xpert'
    }

    axios({
      url: process.env.REACT_APP_SSO_API,
      method: "post",
      data: {
        query: getMentornityToken,
        variables: queryVar
      }
    })
    .then((result) => {
      // console.log('ssoLink:', result.data.data.GetMentornityToken)
      openInNewTab(result.data.data.GetMentornityToken)
    })
  }

  const ErrorComponent = ({error}) => <p>An Error Occurred: {error}</p>

  const LoadingComponent = () => <p>Authentication in progress...</p>

  const LoginSuccess = () => {
    if(account!==null){
      getMentornitySSO(account.idTokenClaims.given_name,
      account.idTokenClaims.family_name,
      account.idTokenClaims["signInNames.emailAddress"],
      account.idTokenClaims.extension_TipologiaUtente)
      return(<p>Redirecting to learn environment...</p>)
    }
  }

  return (
      <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={authRequest}
          errorComponent={ErrorComponent}
          loadingComponent={LoadingComponent}
      >
          <LoginSuccess />

      </MsalAuthenticationTemplate>

    )
}

export default Home

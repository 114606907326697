import React, { useEffect } from 'react'
import './global.css'
import axios from 'axios'

import { MsalAuthenticationTemplate, useMsal, useAccount } from "@azure/msal-react"
import { InteractionType } from "@azure/msal-browser"

const Home = () => {
  const { accounts } = useMsal()
  const account = useAccount(accounts[0] || {})

  useEffect(() => {
    console.log('account:', account)
  }, [account])

  const authRequest = {
      scopes: ["openid", "profile"]
  }

  const openInNewTab = (url) => window.location.replace(url)

  const getThinkificSSO = async(firstname, lastName, email) => {
    axios({
      url: process.env.REACT_APP_SSO_API,
      method: "post",
      data: {
        query: `
        query GetCDPThinkificToken($userEmail: String, $firstName:String, $lastName:String){
          GetCDPThinkificToken(userEmail:$userEmail, firstName:$firstName, lastName:$lastName)
        }`,
        variables:
        {
          "firstName":firstname,
          "lastName":lastName,
          "userEmail":email
        }
      }
    })
    .then((result) => {
      console.log('ssoLink:', result.data.data.GetCDPThinkificToken)
      openInNewTab(result.data.data.GetCDPThinkificToken)
    })
  }

  const ErrorComponent = ({error}) => <p>An Error Occurred: {error}</p>

  const LoadingComponent = () => <p>Authentication in progress...</p>

  const LoginSuccess = () => {
    if(account!==null){
      getThinkificSSO(account.idTokenClaims.given_name,
      account.idTokenClaims.family_name,
      account.idTokenClaims["signInNames.emailAddress"])
      return(<p>Redirecting to learn environment...</p>)
    }
  }

  return (
      <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={authRequest}
          errorComponent={ErrorComponent}
          loadingComponent={LoadingComponent}
      >
          <LoginSuccess />

      </MsalAuthenticationTemplate>

    )
}

export default Home

import React, { useEffect } from "react"
import { useParams } from 'react-router-dom'
import axios from "axios"
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Magic = () => {
  const { id } = useParams()

  const notifyError = () => toast.error("Something went wrong, please try to login again")

  const openLink = (url) => window.location.replace(url)

  useEffect(() => {
    getThinkificSSOMagic(id)
  })

  const getThinkificSSOMagic = async(jwt) => {
    console.log(`jwt: ${jwt}`)
    axios({
      url: process.env.REACT_APP_SSO_API,
      method: "post",
      data: {
        query: `
        query GetCDPThinkificTokenJWT($jwt: String){
          GetCDPThinkificTokenJWT(jwt:$jwt)
        }`,
        variables:
        {
          "jwt":jwt
        }
      }
    })
    .then((result) => {
      if(!result.data.data.GetCDPThinkificTokenJWT) notifyError()
      else openLink(result.data.data.GetCDPThinkificTokenJWT)
    })
    .catch((err) => {
      notifyError()
    })
  }

  return (
    <>
      <ToastContainer />
      <p>Authentication in progres...</p>
    </>
    )
}

export default Magic

import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom"
import './index.css'
import Home from './Home'
import Login from './Login'
import Xpert from './Xpert'
import RedirectMagic from './RedirectMagic'

import { MsalProvider } from "@azure/msal-react"
import { PublicClientApplication } from "@azure/msal-browser"

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    knownAuthorities: [process.env.REACT_APP_KNOWN_AUTHORITIES]
  },
  cache: {
    cacheLocation: "localStorage",
    // storeAuthStateInCookie: isIE || isEdge || isFirefox
  }
}

const pca = new PublicClientApplication(msalConfig)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/xpert" element={<Xpert />} />
          <Route path="/re/:id" element={<RedirectMagic/>} />
        </Routes>
      </Router>
    </MsalProvider>
  </React.StrictMode>
)
